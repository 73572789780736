:root {
    /**
     * Tokens
     */

    /* Surface colors */
    --color-background-main: var(--color-neutral-0);
    --color-background-variant: var(--color-beige-100);
    --color-background-disabled: var(--color-neutral-200);

    /* Text colors */
    --color-text-main: var(--color-neutral-900);
    --color-text-light: var(--color-neutral-700);
    --color-text-overlay: var(--color-neutral-0);
    --color-text-disabled: var(--color-neutral-500);

    /* Border colors */
    --color-border-main: var(--color-neutral-300);
    --color-border-light: var(--color-neutral-200);
    --color-border-disabled: var(--color-neutral-200);

    /* Primary colors */
    --color-primary-main: var(--color-green-700);
    --color-primary-dark: var(--color-green-800);
    --color-primary-hovered: var(--color-green-800);
    --color-primary-pressed: var(--color-green-900);
    --color-primary-disabled: var(--color-green-200);
    --color-primary-contrast-neutral: var(--color-neutral-0);
    --color-primary-contrast-colored: var(--color-yellow-300);

    /* Secondary colors */
    --color-secondary-main: var(--color-yellow-300);
    --color-secondary-dark: var(--color-yellow-500);
    --color-secondary-hovered: var(--color-yellow-500);
    --color-secondary-pressed: var(--color-yellow-600);
    --color-secondary-disabled: var(--color-yellow-100);
    --color-secondary-contrast-neutral: var(--color-neutral-900);

    /* Family colors */
    --color-family-main: var(--color-blue-family-300);
    --color-family-hovered: var(--color-blue-family-600);
    --color-family-pressed: var(--color-blue-family-700);
    --color-family-contrast-neutral: var(--color-neutral-900);

    /* Accent colors */
    --color-accent-main: var(--color-custom-brand-red);
    --color-accent-contrast-neutral: var(--color-neutral-900);

    /* Feedback colors */
    --color-info-main: var(--color-blue-700);
    --color-info-light: var(--color-blue-100);
    --color-info-contrast-neutral: var(--color-neutral-900);
    --color-warning-main: var(--color-orange-600);
    --color-warning-light: var(--color-orange-100);
    --color-warning-contrast-neutral: var(--color-neutral-900);
    --color-success-main: var(--color-green-600);
    --color-success-light: var(--color-green-100);
    --color-success-contrast-neutral: var(--color-neutral-900);
    --color-error-main: var(--color-red-600);
    --color-error-light: var(--color-red-100);
    --color-error-contrast-neutral: var(--color-neutral-900);

    /**
     * Base colors
     */
    --color-red-100: #ffece6;
    --color-red-200: #fec9b9;
    --color-red-300: #fca288;
    --color-red-400: #f88762;
    --color-red-500: #f56e42;
    --color-red-600: #d43f11;
    --color-red-700: #a23210;
    --color-red-800: #71240e;
    --color-red-900: #4a1a0c;
    --color-orange-100: #fff6e4;
    --color-orange-200: #ffe9bd;
    --color-orange-300: #ffdb92;
    --color-orange-400: #ffcc67;
    --color-orange-500: #ffc042;
    --color-orange-600: #ffad0a;
    --color-orange-700: #e59900;
    --color-orange-800: #bd7e00;
    --color-orange-900: #8a5c00;
    --color-yellow-100: #fff6c7;
    --color-yellow-200: #ffee8f;
    --color-yellow-300: #ffe44e;
    --color-yellow-400: #f8d720;
    --color-yellow-500: #eecd11;
    --color-yellow-600: #e0c115;
    --color-yellow-700: #caaf16;
    --color-yellow-800: #b29b19;
    --color-yellow-900: #a08c1c;
    --color-green-100: #e2f2ee;
    --color-green-200: #b4e2d8;
    --color-green-300: #84d0bf;
    --color-green-400: #54bca6;
    --color-green-500: #169e82;
    --color-green-600: #0f8066;
    --color-green-700: #00543c;
    --color-green-800: #003526;
    --color-green-900: #00251b;
    --color-green-700-40: rgb(0 84 60 / 40%);
    --color-green-800-30: rgb(0 53 38 / 30%);
    --color-green-900-30: rgb(0 37 27 / 30%);
    --color-blue-family-100: #f3fbfd;
    --color-blue-family-200: #e0f3f7;
    --color-blue-family-300: #b6dae3;
    --color-blue-family-400: #74b9ca;
    --color-blue-family-600: #539eb1;
    --color-blue-family-700: #1f7e96;
    --color-blue-100: #e3f2fd;
    --color-blue-200: #bbdefb;
    --color-blue-300: #90caf9;
    --color-blue-400: #64b5f6;
    --color-blue-500: #42a5f5;
    --color-blue-600: #2196f3;
    --color-blue-700: #0a6cbd;
    --color-blue-800: #083e91;
    --color-blue-900: #052557;
    --color-neutral-0: #fff;
    --color-neutral-100: #f6f7f8;
    --color-neutral-200: #e9ebec;
    --color-neutral-300: #cfd4d8;
    --color-neutral-400: #abb2ba;
    --color-neutral-500: #9199a1;
    --color-neutral-600: #6f7780;
    --color-neutral-700: #585f66;
    --color-neutral-800: #41474c;
    --color-neutral-900: #1f2226;
    --color-neutral-0-05: rgb(255 255 255 / 5%);
    --color-neutral-0-10: rgb(255 255 255 / 10%);
    --color-neutral-0-20: rgb(255 255 255 / 20%);
    --color-neutral-0-60: rgba(255 255 255 / 60%);
    --color-neutral-0-70: rgba(255 255 255 / 70%);
    --color-neutral-900-30: rgb(31 34 38 / 30%);
    --color-neutral-900-60: rgb(31 34 38 / 60%);
    --color-beige-100: #f7f5ed;
    --color-beige-200: #eee9dd;
    --color-beige-300: #ded4bf;
    --color-beige-400: #d7cbac;
    --color-beige-500: #cfc09b;
    --color-beige-600: #b4a683;
    --color-beige-700: #897e5d;
    --color-beige-800: #786e4f;
    --color-beige-100-10: rgb(247 245 237 / 10%);
    --color-custom-brand-red: #eb5d37;

    /**
     * Deprecated
     */
    --color-deprecated-veraneos: #14716a;
    --color-deprecated-superdarkaneos: #013839;
    --color-deprecated-darkaneos: #005c56;
    --color-deprecated-lime: #12b886;
    --color-deprecated-lime-dark: #038b62;
    --color-deprecated-lime-darker: #095d44;
    --color-deprecated-lime-lightest: #d6f8ee;
    --color-deprecated-lime-alpha-40: rgb(18 184 134 / 40%);
    --color-deprecated-blue: #129cab;
    --color-deprecated-blue-lightest: #dcf6f9;
    --color-deprecated-tan: #b6a467;
    --color-deprecated-tan-dark: #897840;
    --color-deprecated-tan-darkest: #5a4c16;
    --color-deprecated-tan-lightest: #faf8f3;
    --color-deprecated-pink: #faab95;
    --color-deprecated-red: #db2c24;
    --color-deprecated-red-dark: #b50700;
    --color-deprecated-red-lightest: #ffeceb;
    --color-deprecated-orange: #ee641c;
    --color-deprecated-orange-lightest: #ffe8d7;
    --color-deprecated-gold: #ffb200;
    --color-deprecated-purple: #7b0051;
    --color-deprecated-ink: #343a40;
    --color-deprecated-ink-extra-light: #ebecef;
    --color-deprecated-ink-light: #868e96;
    --color-deprecated-ink-lighter: #adb5bd;
    --color-deprecated-ink-alpha-60: rgb(52 58 64 / 60%);
    --color-deprecated-paper: #faf9f8;
    --color-deprecated-paper-darker: #eee9e5;
    --color-deprecated-paper-darker-alpha-80: rgb(238 233 229 / 80%);
    --color-deprecated-paper-dark: #f5f3f1;
    --color-deprecated-white: #fff;
    --color-deprecated-white-alpha-10: rgb(255 255 255 / 10%);
    --color-deprecated-white-alpha-30: rgb(255 255 255 / 30%);
    --color-deprecated-white-alpha-60: rgb(255 255 255 / 60%);
    --color-deprecated-black: #000;
    --color-deprecated-black-alpha-10: rgb(0 0 0 / 10%);
    --color-deprecated-black-alpha-30: rgb(0 0 0 / 30%);
    --color-deprecated-img-placeholder: var(--color-deprecated-paper-darker);
    --color-deprecated-img-hover: rgb(31 34 38 / 55%);
}
