:root {
    --font-family-open-sauce-one: 'Open Sauce One', Arial, sans-serif;
    --font-family-moret: Moret, 'Times New Roman', serif;
    --font-family-primary: var(--font-family-open-sauce-one);
    --font-family-secondary: var(--font-family-moret);

    /* FONT WEIGHT */
    --font-weight-normal: var(--font-weight-regular); /* @deprecated */
    --font-weight-regular: 400;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 800;

    /* PRIMARY FONT-SIZE */
    --font-size-xl: 4rem;
    --font-size-l: 2.8rem;
    --font-size-m: 2.4rem;
    --font-size-s: 2rem;
    --font-size-body: 1.6rem;
    --font-size-xs: 1.4rem;
    --font-size-xxs: 1.2rem;

    /* SECONDARY FONT-SIZE */
    --font-size-secondary-xxl: 7rem;
    --font-size-secondary-xl: 5.6rem;
    --font-size-secondary-l: 4.8rem;
    --font-size-secondary-m: 4rem;
    --font-size-secondary-s: 3.2rem;
    --font-size-secondary-xs: 2.8rem;
    --font-size-secondary-xxs: 2.4rem;

    /* PRIMARY LINE-HEIGHT */
    --line-height-xl: 5.6rem;
    --line-height-l: 4rem;
    --line-height-m: 3.2rem;
    --line-height-s: 2.8rem;
    --line-height-body: 2.4rem;
    --line-height-xs: 2rem;
    --line-height-xxs: 1.8rem;

    /* SECONDARY LINE-HEIGHT */
    --line-height-secondary-xxl: 7.4rem;
    --line-height-secondary-xl: 6rem;
    --line-height-secondary-l: 5.4rem;
    --line-height-secondary-m: 4.8rem;
    --line-height-secondary-s: 4rem;
    --line-height-secondary-xs: 3.6rem;
    --line-height-secondary-xxs: 3.2rem;
}

@media (min-width: 768px) {
    :root {
        /* PRIMARY FONT-SIZE RESPONSIVE */
        --font-size-xl: 4.8rem;

        /* SECONDARY FONT-SIZE RESPONSIVE */
        --font-size-secondary-xxl: 9.2rem;
        --font-size-secondary-xl: 7rem;
        --font-size-secondary-l: 5.6rem;
        --font-size-secondary-m: 4.8rem;

        /* PRIMARY LINE-HEIGHT RESPONSIVE */
        --line-height-xl: 6.4rem;

        /* SECONDARY LINE-HEIGHT RESPONSIVE */
        --line-height-secondary-xxl: 9.2rem;
        --line-height-secondary-xl: 7.4rem;
        --line-height-secondary-l: 6rem;
        --line-height-secondary-m: 5.4rem;
    }
}
