:root {
    --space-4: 0.4rem;
    --space-8: 0.8rem;
    --space-12: 1.2rem;
    --space-16: 1.6rem;
    --space-24: 2.4rem;
    --space-32: 3.2rem;
    --space-40: 4rem;
    --space-48: 4.8rem;
    --space-56: 5.6rem;
    --space-64: 6.4rem;
    --space-72: 7.2rem;
    --space-80: 8rem;
    --space-88: 8.8rem;
    --space-96: 9.6rem;
    --space-104: 10.4rem;
    --space-112: 11.2rem;
    --space-120: 12rem;
    --space-152: 15.2rem;
}
