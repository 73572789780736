/* source: https://mui.com/material-ui/customization/z-index/ */

:root {
    --z-index-base: 1000;
    --z-index-sticky-bar: 1050;
    --z-index-fab: 1050;
    --z-index-appbar: 1100;
    --z-index-drawer: 1200;
    --z-index-modal: 1300;
    --z-index-snackbar: 1400;
    --z-index-tooltip: 1500;
}
