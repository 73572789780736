.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(-0.5 * var(--grid-gutter-x));
    margin-left: calc(-0.5 * var(--grid-gutter-x));
}

.row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--grid-gutter-x) * 0.5);
    padding-left: calc(var(--grid-gutter-x) * 0.5);
}

.col {
    flex: 1 0 0;
}

.col-1 {
    flex: 0 0 auto;
    width: 8.3333%;
}

.col-2 {
    flex: 0 0 auto;
    width: 16.6667%;
}

.col-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-4 {
    flex: 0 0 auto;
    width: 33.3333%;
}

.col-5 {
    flex: 0 0 auto;
    width: 41.6667%;
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-7 {
    flex: 0 0 auto;
    width: 58.3333%;
}

.col-8 {
    flex: 0 0 auto;
    width: 66.6667%;
}

.col-9 {
    flex: 0 0 auto;
    width: 75%;
}

.col-10 {
    flex: 0 0 auto;
    width: 83.3333%;
}

.col-11 {
    flex: 0 0 auto;
    width: 91.6667%;
}

.col-12 {
    flex: 0 0 auto;
    width: 100%;
}

.hidden {
    /* REMINDER: This should not be marked as important because the rule no longer works: hidden xl:block */
    display: none !important;
}

.hide-below-sm,
.hide-below-md,
.hide-below-lg,
.hide-below-xl {
    display: none;
}

@media (min-width: 576px) {
    .hide-below-sm {
        display: block;
    }
}

@media (min-width: 768px) {
    .hide-below-md {
        display: block;
    }
}

@media (min-width: 992px) {
    .hide-below-lg {
        display: block;
    }
}

@media (min-width: 1200px) {
    .hide-below-xl {
        display: block;
    }
}

@media (max-width: 575.98px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .hidden-lg {
        display: none !important;
    }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
    .hidden-xl {
        display: none !important;
    }
}

@media (min-width: 1400px) {
    .hidden-xxl {
        display: none !important;
    }
}

@media (min-width: 576px) {
    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.3333%;
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.6667%;
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.3333%;
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.6667%;
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.3333%;
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.6667%;
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.3333%;
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.6667%;
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .col-sm-auto {
        flex: 1 0 0;
        width: auto;
    }
}

@media (min-width: 768px) {
    .col-md-1 {
        flex: 0 0 auto;
        width: 8.3333%;
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.6667%;
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.3333%;
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.6667%;
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.3333%;
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.6667%;
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.3333%;
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.6667%;
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .col-md-auto {
        flex: 1 0 0;
        width: auto;
    }
}

@media (min-width: 992px) {
    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.3333%;
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.6667%;
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.3333%;
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.6667%;
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.3333%;
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.6667%;
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.3333%;
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.6667%;
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .col-lg-auto {
        flex: 1 0 0;
        width: auto;
    }
}

@media (min-width: 1200px) {
    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.3333%;
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.6667%;
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.3333%;
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.6667%;
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.3333%;
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.6667%;
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.3333%;
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.6667%;
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .col-xl-auto {
        flex: 1 0 0;
        width: auto;
    }
}

@media (min-width: 1400px) {
    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.3333%;
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.6667%;
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.3333%;
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.6667%;
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.3333%;
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.6667%;
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.3333%;
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.6667%;
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .col-xxl-auto {
        flex: 1 0 0;
        width: auto;
    }
}
