@tailwind utilities;

.no-scroll {
    overflow: hidden;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

@font-face {
    font-style: normal;
    font-weight: 400;
    src:
        url('/static/varanasi/assets/fonts/OpenSauceOne/open-sauce-one-regular.woff2')
            format('woff2'),
        url('/static/varanasi/assets/fonts/OpenSauceOne/open-sauce-one-regular.woff') format('woff');
    font-family: 'Open Sauce One';
    font-display: swap;
}

@font-face {
    font-style: normal;
    font-weight: 700;
    src:
        url('/static/varanasi/assets/fonts/OpenSauceOne/open-sauce-one-bold.woff2') format('woff2'),
        url('/static/varanasi/assets/fonts/OpenSauceOne/open-sauce-one-bold.woff') format('woff');
    font-family: 'Open Sauce One';
    font-display: swap;
}

@font-face {
    font-style: normal;
    font-weight: 800;
    src:
        url('/static/varanasi/assets/fonts/OpenSauceOne/open-sauce-one-extrabold.woff2')
            format('woff2'),
        url('/static/varanasi/assets/fonts/OpenSauceOne/open-sauce-one-extrabold.woff')
            format('woff');
    font-family: 'Open Sauce One';
    font-display: swap;
}

@font-face {
    font-style: normal;
    font-weight: 400;
    src: url('/static/varanasi/assets/fonts/Moret/moret-regular.otf') format('opentype');
    font-family: Moret;
    font-display: swap;
}

@font-face {
    font-style: normal;
    font-weight: 700;
    src: url('/static/varanasi/assets/fonts/Moret/moret-bold.otf') format('opentype');
    font-family: Moret;
    font-display: swap;
}
