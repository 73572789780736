html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
}

body {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body,
input,
button {
    color: var(--color-text-main);
    font-family: var(--font-family-primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: inherit;
    font-size: 100%;
    line-height: inherit;
}

a {
    color: inherit;
    text-decoration: none;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
    list-style-type: none;
}

p {
    margin: 0;
}

hr {
    border: 0;
    border-top: 1px solid #eee;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    outline-color: var(--color-deprecated-paper-darker);
}
