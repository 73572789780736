.imageOverlay::after,
.imageOverlayDark::after,
.imageOverlayHoverDark::after {
    position: absolute;
    inset: 0;
    background-image: var(--gradient-hover-image);
    content: '';
    opacity: 0.75;
}

.imageOverlayDark::after {
    opacity: 1;
}

.imageOverlayHoverDark::after {
    transition: opacity 0.2s;
}

.imageOverlayHoverDark:hover::after {
    opacity: 1;
}
