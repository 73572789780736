.animate {
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-fill-mode: backwards;
}

.animate.animate-fast {
    animation-duration: 0.2s;
    animation-timing-function: ease;
}

.animate.animation-slide-up {
    animation-name: slide;
}

.animate.animation-fade-in {
    animation-name: fade-in;
}

@keyframes slide {
    0% {
        transform: translateY(10%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    .animate {
        animation: none !important;
    }
}
