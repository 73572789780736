/* Primary Scale */

.font-xl-extrabold {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
    font-family: var(--font-family-primary);
}

.font-l-extrabold {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
    font-family: var(--font-family-primary);
}

.font-m-extrabold {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-m);
    line-height: var(--line-height-m);
    font-family: var(--font-family-primary);
}

.font-s-extrabold {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-s);
    line-height: var(--line-height-s);
    font-family: var(--font-family-primary);
}

.font-body-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    font-family: var(--font-family-primary);
}

.font-body-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    font-family: var(--font-family-primary);
}

.font-xs-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-xs);
    font-family: var(--font-family-primary);
}

.font-xs-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-xs);
    font-family: var(--font-family-primary);
}

.font-xxs-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-xxs);
    line-height: var(--line-height-xxs);
    font-family: var(--font-family-primary);
}

.font-xxs-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xxs);
    line-height: var(--line-height-xxs);
    font-family: var(--font-family-primary);
}

/* Secondary Scale */

.font-secondary-xxl-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-secondary-xxl);
    line-height: var(--line-height-secondary-xxl);
    font-family: var(--font-family-secondary);
}

.font-secondary-xl-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-secondary-xl);
    line-height: var(--line-height-secondary-xl);
    font-family: var(--font-family-secondary);
}

.font-secondary-l-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-secondary-l);
    line-height: var(--line-height-secondary-l);
    font-family: var(--font-family-secondary);
}

.font-secondary-m-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-secondary-m);
    line-height: var(--line-height-secondary-m);
    font-family: var(--font-family-secondary);
}

.font-secondary-s-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-secondary-s);
    line-height: var(--line-height-secondary-s);
    font-family: var(--font-family-secondary);
}

.font-secondary-s-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-secondary-s);
    line-height: var(--line-height-secondary-s);
    font-family: var(--font-family-secondary);
}

.font-secondary-xs-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-secondary-xs);
    line-height: var(--line-height-secondary-xs);
    font-family: var(--font-family-secondary);
}

.font-secondary-xxs-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-secondary-xxs);
    line-height: var(--line-height-secondary-xxs);
    font-family: var(--font-family-secondary);
}

.link {
    padding: 0;
    border: 0;
    background-color: transparent;
    font-weight: var(--font-weight-bold);
    text-decoration: underline;
    cursor: pointer;
}

.link:hover {
    text-decoration: none;
}

.text-shadow {
    text-shadow: rgb(0 0 0 / 30%) 1px 1px 2px;
}
